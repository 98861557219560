import logo from "./Elbocone.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <h2>Elbocone is coming soon!</h2>
          <p>
            For any further information, please contact
            <a href="mailto:andythomp1@gmail.com"> info@elbocone.com</a>
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
